import React from 'react'

const ArrowRight = () => (
	<svg
		className="my-6 h-7 w-10"
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		viewBox="0 0 16 16"
	>
		<path
			fillRule="evenodd"
			d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
		/>
	</svg>
)

export default ArrowRight
