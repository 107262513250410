import React from 'react'
import Layout from '../components/Layout'
import CustomCarousel from '../components/CustomCarousel'
import { graphql } from 'gatsby'

const IndexPageTemplate = ({ videos }) => {
	return (
		<main className="col-span-4 col-start-1 row-start-1 row-end-4 w-full">
			<CustomCarousel videos={videos} />
		</main>
	)
}

const NotFoundPage = ({ data, location }) => {
	console.log(data)
	return (
		<Layout location={location}>
			<IndexPageTemplate
				videos={data.markdownRemark.frontmatter.carousel_items}
			/>
		</Layout>
	)
}

export const data = graphql`
	query NotFoundQuery {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			fields {
				slug
			}
			frontmatter {
				carousel_items {
					video {
						id
						frontmatter {
							title
							director
							desktopSource {
								source
								poster
							}
							mobileSource {
								source
								poster
							}
							fullSource
						}
					}
				}
			}
			id
		}
	}
`

export default NotFoundPage
