import React from 'react'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'

const ArrowButton = ({ orientation, onClick, label, className, ref }) => {
	const isLeft = orientation == 'LEFT'
	return (
		<button
			ref={ref}
			className={`${className}
                cursor pointer mx-2 flex h-12 w-12 items-center
                rounded-full border-2 text-4xl text-white`}
			type="button"
			onClick={onClick}
			title={label}
		>
			{isLeft ? <ArrowLeft /> : <ArrowRight />}
		</button>
	)
}

export default ArrowButton
