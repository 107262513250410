// CustomCarousel.js
import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import CarouselVideo from './CarouselVideo'
import CustomIndicator from './CustomIndicator'
import { useWindowDimensions } from '../utils/hooks'
import screenfull from 'screenfull'
import ArrowButton from './ArrowButton'

const CustomCarousel = ({ videos }) => {
	const customRenderItem = (item, props) => (
		<item.type {...item.props} {...props} />
	)

	const [isFullScreen, setFullScreen] = useState(false)
	const screenSize = useWindowDimensions()

	const handleSource = (desktop, mobile, fullSource) => {
		const responsiveSource =
			screenSize.width < 640 ? mobile.source : desktop.source
		const source = isFullScreen ? fullSource : responsiveSource
		return source
	}

	useEffect(() => {
		if (typeof document !== 'undefined' && screenfull.isEnabled) {
			screenfull.on('change', (event) => {
				if (screenfull.isFullscreen) {
					setFullScreen(true)
				} else {
					setFullScreen(false)
				}
			})
		}
	}, [])

	return (
		<Carousel
			className="w-full"
			showThumbs={false}
			showStatus={false}
			infiniteLoop={!isFullScreen}
			interval={15000}
			autoPlay={!isFullScreen}
			stopOnHover={false}
			swipeable
			renderItem={customRenderItem}
			renderArrowPrev={(onClickHandler, hasPrev, label) =>
				hasPrev && (
					<ArrowButton
						label={label}
						onClick={onClickHandler}
						className="absolute left-0 top-1/2
					z-10 hidden lg:flex"
						orientation={'LEFT'}
					/>
				)
			}
			renderArrowNext={(onClickHandler, hasNext, label) =>
				hasNext && (
					<ArrowButton
						label={label}
						onClick={onClickHandler}
						className="absolute right-0 top-1/2 z-10 hidden justify-center lg:flex"
						orientation={'RIGHT'}
					/>
				)
			}
			renderIndicator={(onClickHandler, isSelected, index, label) =>
				CustomIndicator(onClickHandler, isSelected, index, label)
			}
		>
			{videos.map(
				({
					video: {
						frontmatter: {
							desktopSource,
							mobileSource,
							fullSource,
							title,
							director,
							text,
						},
						id,
					},
				}) => (
					<CarouselVideo
						title={title}
						key={id}
						source={handleSource(desktopSource, mobileSource, fullSource)}
						fullSource={fullSource}
						director={director}
						text={text}
						isFullScreen={isFullScreen}
						className={`
					relative h-screen
					w-screen cursor-pointer
					bg-black brightness-90
					filter ${isFullScreen ? '!object-contain' : ''}`}
						captionClassName={`
					absolute left-[2rem] lg:left-[5rem] top-1/3
					text-5xl text-left lg:text-8xl font-light text-white
					flex flex-col font-light whitespace-wrap
					z-40 max-w-max pr-4`}
					/>
				)
			)}
		</Carousel>
	)
}

export default CustomCarousel
