import React from 'react'

const CustomIndicator = (onClickHandler, isSelected, index, label) => {
	return (
		<>
			{isSelected ? (
				// Indicador ativo
				<li
					className="mr-4 inline-block h-[0.12rem] w-[2vw] max-w-[1.5rem] bg-white text-6xl"
					aria-label={`Selected: ${label} ${index + 1}`}
					title={`Selected: ${label} ${index + 1}`}
				/>
			) : (
				// Default
				<li
					className="mr-4 inline-block h-[0.12rem] w-[2vw] max-w-[1.5rem] bg-white bg-opacity-50 text-6xl"
					onClick={onClickHandler}
					onKeyDown={onClickHandler}
					value={index}
					key={index}
					role="button"
					tabIndex={0}
					title={`${label} ${index + 1}`}
					aria-label={`${label} ${index + 1}`}
				/>
			)}
		</>
	)
}

export default CustomIndicator
